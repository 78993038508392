<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Payment</h3>
            </div>
            <div class="card-body">
              <div class="row1">
                <form>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-md-12">
                        <base-input label="Member">
                          <el-select
                            style="width: 100%"
                            v-model="model.member_name"
                            placeholder="Member"
                            @change="getMemberChargeList($event)"
                            :disabled="ismembername"
                          >
                            <el-option
                              v-for="option in MemberData"
                              :value="option.id"
                              :label="option.full_name"
                              :key="option.id"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                        <div
                          class="error mb-2"
                          v-if="v$.model.member_name.$error"
                        >
                          Field is required.
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <base-input label="Date">
                          <flat-picker
                            @on-open="focus"
                            @on-close="blur"
                            :config="{ altFormat: 'd/m/Y', altInput: true }"
                            class="form-control datepicker"
                            v-model="model.date"
                          >
                          </flat-picker>
                        </base-input>
                        <div class="error mb-2" v-if="v$.model.date.$error">
                          Field is required.
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <base-input
                          disabled
                          :value="model.prepaid"
                          @input="
                            (event) => (model.prepaid = event.target.value)
                          "
                          alternative=""
                          label="Prepaid Balance($)"
                          input-classes="form-control-alternative"
                        />
                      </div>

                      <div class="col-lg-2">
                        <base-input
                          :value="model.amount"
                          alternative=""
                          label="+ Amount($)"
                          input-classes="form-control-alternative"
                          @input="
                            (event) => (model.amount = event.target.value)
                          "
                          @keyup="onTotal(model.amount)"
                        />
                        <div class="error mb-2" v-if="v$.model.amount.$error">
                          Valid Amount required.
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <base-input
                          disabled
                          :value="model.total"
                          alternative=""
                          label="= Total($)"
                          input-classes="form-control-alternative"
                        />
                      </div>

                      <div class="col-lg-6">
                        <base-input label="Payment Mode">
                          <el-select
                            style="width: 100%"
                            v-model="model.paid"
                            placeholder=" Payment Mode"
                          >
                            <el-option
                              v-for="option in paymentArr"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                        <div class="error mb-2" v-if="v$.model.paid.$error">
                          Field is required.
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <base-input
                          input-classes="form-control-alternative"
                          label="Note"
                          :value="model.bulk_note"
                        >
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Note"
                            v-model="model.note"
                          ></textarea>
                        </base-input>
                      </div>

                      <div class="row1" style="width: 100%">
                        <div class="table-responsive">
                          <base-table
                            class="table align-items-center table-flush"
                            :class="type === 'dark' ? 'table-dark' : ''"
                            :thead-classes="
                              type === 'dark' ? 'thead-dark' : 'thead-light'
                            "
                            tbody-classes="list"
                            :data="meberchargeData"
                          >
                            <template v-slot:columns>
                              <th>Description</th>
                              <th>Date</th>
                              <th>Amount($)</th>
                              <th>Balance</th>
                              <th>Amount($)</th>
                            </template>

                            <template v-slot:default="row" v-if="member_table">
                              <td class="budget">
                                {{ row.item.desciption }}
                              </td>
                              <td class="budget">
                                {{ row.item.created_date }}
                              </td>
                              <td class="budget">
                                {{ row.item.amount }}
                              </td>
                              <td class="budget">
                                {{ row.item.balance }}
                              </td>

                              <td>
                                <base-input
                                  :value="row.item.paid_amount"
                                  alternative=""
                                  input-classes="form-control-alternative"
                                  @change="
                                    onChange(
                                      row.item.paid_amount,
                                      row.item.balance,
                                      row
                                    )
                                  "
                                  @input="
                                    (event) =>
                                      (row.item.paid_amount =
                                        event.target.value)
                                  "
                                />
                              </td>
                            </template>
                            <template v-if="!meberchargeData"
                              >Not Found</template
                            >
                          </base-table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="id.data == 'undefined'">
                    <div class="col-md-5"></div>
                    <div class="col-md-4">
                      <button
                        type="button"
                        class="btn btn-default"
                        v-on:click="create()"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                  <div class="row" v-if="id.data != 'undefined'">
                    <div class="col-md-5"></div>
                    <div class="col-md-4">
                      <button
                        type="button"
                        class="btn btn-default"
                        v-on:click="Update()"
                      >
                        Create
                      </button>

                      <button
                        type="button"
                        class="btn btn-default"
                        v-on:click="$router.push('/account/account_details')"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </form>
                <br />
                <div v-if="isLoading">
                  <base-alert type="success" dismissible>
                    <span class="alert-inner--text"
                      ><strong>Success!</strong> Record Created
                      Successfully</span
                    >
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </base-alert>
                </div>
                <div v-if="isUpdateLoading">
                  <base-alert type="success" dismissible>
                    <span class="alert-inner--text"
                      ><strong>Success!</strong> Record Updated
                      Successfully</span
                    >
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </base-alert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const memberRepository = RepositoryFactory.get("member");
const paymentRepository = RepositoryFactory.get("payment");
const addchargeRepository = RepositoryFactory.get("addcharge");
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import { ElSelect, ElOption } from "element-plus";
export default {
  components: {
    flatPicker,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  validations() {
    return {
      model: {
        member_name: { required },
        date: { required },
        paid: { required },
        amount: { required, numeric },
      },
    };
  },
  name: "Payment",
  data() {
    //
    return {
      v$: useVuelidate(),
      id: {},
      isUpdateLoading: false,
      member_table: false,
      isLoading: false,
      ismembername: false,
      paid_amount: {},
      membervalue: {},
      PaymentData: [],
      MemberData: [],
      memberCharge: [],
      meberchargeData: [],
      paymentArr: [
        { value: "Bank", label: "Bank" },
        { value: "Prepaid Balance", label: "Prepaid Balance" },
        { value: "Cash", label: "Cash" },
        { value: "Credit Card", label: "Credit Card" },
        { value: "Bank Transfer", label: "Bank Transfer" },
        { value: "Cheque", label: "Cheque" },
        { value: "Account Credit", label: "Account Credit" },
        { value: "Contra", label: "Contra" },
        { value: "Adjustment", label: "Adjustment" },
        { value: "Account Transfer", label: "Account Transfer" },
      ],
      model: {},
    };
  },
  methods: {
    onTotal(value) {
      this.model.total = parseInt(value)
        ? parseInt(value) + parseInt(this.model.prepaid)
        : 0;
    },
    onChange(value, balance, row) {
      value = parseFloat(value);
      balance = parseFloat(balance);
      if (value > balance) {
        row.item.paid_amount = balance;
      }
      let sumTotal = 0;
      for (let index2 = 0; index2 < this.meberchargeData.length; index2++) {
        const element2 = this.meberchargeData[index2];
        let sum = element2.paid_amount;

        if (sum) {
          sumTotal = parseFloat(sumTotal) + parseFloat(sum);
        }
      }
      if (sumTotal > this.model.total) {
        row.item.paid_amount = 0;
      }
      if (value < 0) {
        row.item.paid_amount = 0;
      }
    },
    async create() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }
      this.model.payment_details = this.meberchargeData;
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await paymentRepository.create(data);
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.$router.push("/payment/view_payment");
          this.isLoading = false;
        }, 3000);
      }
      this.memberCharge = JSON.stringify(this.meberchargeData);
      let data5 = RepositoryFactory.JSON_to_URLEncoded(
        this.memberCharge,
        "data",
        []
      );

      let response2 = await addchargeRepository.multiCreate(data5);
      if (response2.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 3000);
      }
    },
    async Update() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }
      this.model.payment_details = this.meberchargeData;
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await paymentRepository.create(data);
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.$router.push("/account/account_details");
          this.isLoading = false;
        }, 3000);
      }
      this.memberCharge = JSON.stringify(this.meberchargeData);
      let data5 = RepositoryFactory.JSON_to_URLEncoded(
        this.memberCharge,
        "data",
        []
      );

      let response2 = await addchargeRepository.multiCreate(data5);
      if (response2.data.status == 101) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 3000);
      }
    },
    getList() {
      memberRepository.getMember().then((data) => {
        this.tableData = Object.freeze(data);
        this.MemberData = this.tableData.data;
      });
    },
    getMemberChargeList(value) {
      memberRepository.getMemberByid(value).then((data) => {
        this.membervalue = data.data[0];
        if (data.data[0]) {
          this.model.prepaid = data.data[0].prepaid;
        }
      });
      if (value) {
        this.member_table = true;
      } else {
        this.member_table = false;
      }

      addchargeRepository.getdataBymember(value).then((data) => {
        this.tableData = Object.freeze(data);
        this.meberchargeData = [];

        for (let index = 0; index < this.tableData.data.length; index++) {
          const element = this.tableData.data[index];
          element.paid_amount = 0;
          if (element.balance != 0) {
            this.meberchargeData.push(element);
          }
        }
      });
    },
  },

  mounted() {
    this.id = this.$route.params;
    if (this.id != "undefined") {
      this.getMemberChargeList(this.id.data);
      memberRepository.getMemberByid(this.id.data).then((data) => {
        if (data.data.length != 0) {
          this.model.member_name = data.data[0].id;

          this.ismembername = true;
        }
      });
    }

    // paymentRepository.getPaymentByid(this.id.data).then((data) => {
    //   if (data.data.length != 0) {
    //     this.model = data.data[0];
    //   }
    // });
    this.getList();
  },
};
</script>

<style></style>
